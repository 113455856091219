import React, { createContext, useState, useEffect, useCallback } from 'react';

// Create the AuthContext
export const AuthContext = createContext();

// AuthProvider component to wrap your app
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null means loading state

  const token = localStorage.getItem('token');

  // Memoize the checkAuthStatus function
  const checkAuthStatus = useCallback(() => {
    return fetch(`${process.env.REACT_APP_API_URL}/Auth/check`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
      .then(response => {
        setIsAuthenticated(response.ok);
      })
      .catch(error => {
        console.error("Error checking auth status:", error);
        setIsAuthenticated(false);
        localStorage.removeItem('user-info');
        localStorage.removeItem('token');
      });
  }, [token]); // Add token as a dependency, since it might change

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]); // Add checkAuthStatus to the dependency array

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children} {/* Render the child components */}
    </AuthContext.Provider>
  );
};
