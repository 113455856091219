import React, { useState, useRef } from 'react';
import { Box, Container, Typography, TextField, Button, IconButton, CircularProgress } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [captchaToken, setCaptchaToken] = useState(null);
  const [loading, setLoading] = useState(false); // New loading state
  const captchaRef = useRef(null);

  const onVerifyCaptcha = (token) => {
    setCaptchaToken(token);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!captchaToken) {
      toast.error("Please verify captcha.");
      return;
    }

    setLoading(true); // Start loading
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/Contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...formData, captchaToken })
      });

      if (response.ok) {
        const result = await response.text();
        toast.success(result);
        setFormData({ name: '', email: '', message: '' });
        setCaptchaToken(null);
        if (captchaRef.current) {
          captchaRef.current.resetCaptcha();
        }
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || "Error sending message.");
      }
    } catch (error) {
      toast.error("An unexpected error occurred.");
      console.error("Error:", error);
    } finally {
      setLoading(false); // Stop loading after completion
    }
  };

  return (
    <Box id="contact" sx={{ py: 5, backgroundColor: '#f8f9fa', position: 'relative' }}>
      <Container maxWidth="sm">
        <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
          Contáctanos
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            maxWidth: '600px',
            mx: 'auto',
            backgroundColor: '#ffffff',
            borderRadius: 2,
            boxShadow: 3,
            p: 3
          }}
        >
          <TextField
            fullWidth
            label="Nombre"
            name="name"
            value={formData.name}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            type="email"
            required
          />
          <TextField
            fullWidth
            label="Mensaje"
            name="message"
            value={formData.message}
            onChange={handleChange}
            margin="normal"
            multiline
            rows={4}
            variant="outlined"
            required
          />
          <HCaptcha
            sitekey={process.env.REACT_APP_CAPTCHA}
            onVerify={onVerifyCaptcha}
            ref={captchaRef}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            sx={{
              mt: 2,
              backgroundColor: '#007bff',
              '&:hover': {
                backgroundColor: '#0056b3'
              }
            }}
          >
            {loading ? <CircularProgress size={30} color="inherit" /> : 'Enviar'}
          </Button>
        </Box>

        {/* Social Media Icons */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 4,
            gap: 2
          }}
        >
          <IconButton
            component="a"
            href="https://www.facebook.com/piso7.co/"
            target="_blank"
            rel="noopener"
            aria-label="Facebook"
            sx={{
              position: 'relative',
              color: '#3b5998',
              '&:hover': {
                color: '#F5C250',
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  width: '36px',
                  height: '36px',
                  borderRadius: '50%',
                  backgroundColor: '#e0e0e0',
                  transform: 'translate(-50%, -50%)',
                  zIndex: -1
                }
              },
              transition: 'color 0.3s ease'
            }}
          >
            <FacebookIcon fontSize="large" />
          </IconButton>
          <IconButton
            component="a"
            href="https://www.instagram.com/piso7.co/"
            target="_blank"
            rel="noopener"
            aria-label="Instagram"
            sx={{
              position: 'relative',
              color: '#e4405f',
              '&:hover': {
                color: '#F5C250',
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  width: '36px',
                  height: '36px',
                  borderRadius: '50%',
                  backgroundColor: '#e0e0e0',
                  transform: 'translate(-50%, -50%)',
                  zIndex: -1
                }
              },
              transition: 'color 0.3s ease'
            }}
          >
            <InstagramIcon fontSize="large" />
          </IconButton>
          <IconButton
            component="a"
            href="https://www.linkedin.com/company/piso7/about/"
            target="_blank"
            rel="noopener"
            aria-label="LinkedIn"
            sx={{
              position: 'relative',
              color: '#0e76a8',
              '&:hover': {
                color: '#F5C250',
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  width: '36px',
                  height: '36px',
                  borderRadius: '50%',
                  backgroundColor: '#e0e0e0',
                  transform: 'translate(-50%, -50%)',
                  zIndex: -1
                }
              },
              transition: 'color 0.3s ease'
            }}
          >
            <LinkedInIcon fontSize="large" />
          </IconButton>
        </Box>
      </Container>
      <ToastContainer />
    </Box>
  );
};

export default Contact;
