import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  TableFooter,
  IconButton,
  Box,
  Tooltip,
  Grid,
  Paper,
  CircularProgress
} from '@mui/material';
import { Delete, Visibility } from '@mui/icons-material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { StyledTableRow, StyledTableCell, StyledTableHeaderCell } from '../../styles/TableStyles';
import { deleteProperty, getProperties } from '../../utils/propertyUtils';
import DeleteModal from '../../components/DeleteModal';
import { pluralize } from '../../utils/methods';
import { formatDate } from 'date-fns';

const ListProperties = () => {
  const [properties, setProperties] = useState([]);
  const [orderDirection, setOrderDirection] = useState('asc');
  const [orderBy, setOrderBy] = useState('cityId');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      var errorMessage = sessionStorage.getItem("cantSaveMeeting");
      sessionStorage.removeItem("cantSaveMeeting");
      if (errorMessage)
        toast.error(errorMessage);
      setLoading(true);
      const data = await getProperties();
      setProperties(data);
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDetailClick = (estimate) => {
    navigate(`/property/${estimate.id}`);
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    const onSuccess = () => {
      getProperties().then((data) => {
        setProperties(data);
        setDeleteId(null);
      });
    };

    const onFinally = () => {
      setShowModal(false);
    };

    deleteProperty(deleteId, onSuccess, null, onFinally);
  };


  const handleCancelDelete = () => {
    setDeleteId(null);
    setShowModal(false);
  };

  const sortedEstimates = properties ? properties.slice().sort((a, b) => {
    const aValue = orderBy.includes('.') ? a[orderBy.split('.')[0]][orderBy.split('.')[1]] : a[orderBy];
    const bValue = orderBy.includes('.') ? b[orderBy.split('.')[0]][orderBy.split('.')[1]] : b[orderBy];

    if (orderDirection === 'asc') {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  }) : [];

  return (
    <Box sx={{ width: '90%', padding: 2, margin: '0 auto' }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Paper elevation={3}>
            <TableContainer>
              {loading ? (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh'
                }}>
                  <CircularProgress size={80} />
                </div>
              ) : (
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableHeaderCell>
                        <TableSortLabel
                          active={orderBy === 'nickname'}
                          direction={orderBy === 'nickname' ? orderDirection : 'asc'}
                          onClick={() => handleRequestSort('nickname')}
                        >
                          Detalles
                        </TableSortLabel>
                      </StyledTableHeaderCell>
                      <StyledTableHeaderCell>
                        <TableSortLabel
                          active={orderBy === 'meeting'}
                          direction={orderBy === 'meeting' ? orderDirection : 'asc'}
                          onClick={() => handleRequestSort('meeting')}
                        >
                          Reunión
                        </TableSortLabel>
                      </StyledTableHeaderCell>
                      <StyledTableHeaderCell>
                        <TableSortLabel
                          active={orderBy === 'estimatedValue'}
                          direction={orderBy === 'estimatedValue' ? orderDirection : 'asc'}
                          onClick={() => handleRequestSort('estimatedValue')}
                        >
                          Ganancia diaria estimada
                        </TableSortLabel>
                      </StyledTableHeaderCell>
                      <StyledTableHeaderCell>Acciones</StyledTableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedEstimates
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((property) => (
                        <StyledTableRow key={property.id}>
                          <StyledTableCell align="center">
                            <h3>{property.nickname || "Propiedad"} en {property.neighborhood}, {property.city} (Colombia)</h3>
                            <br></br>
                            {pluralize(property.guests, "guest")} ·{' '}
                            {pluralize(property.bedrooms, "bedroom")} ·{' '}
                            {pluralize(property.beds, "bed")} ·{' '}
                            {pluralize(property.baths, "bath")}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {property.meeting === null ?
                            "--" :
                            formatDate(property.meeting, "dd/MM/yyyy - hh:mm")
                            }
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {property.estimatedValue === 0
                              ? "Pronto será actualizado"
                              : `$ ${property.estimatedValue.toLocaleString('es-CO')} COP`}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            <Tooltip title="Ver Detalles">
                              <IconButton
                                color="primary"
                                onClick={() => handleDetailClick(property)}
                              >
                                <Visibility />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Eliminar">
                              <IconButton
                                color="secondary"
                                onClick={() => handleDeleteClick(property.id)}
                                sx={{ color: 'red' }}
                                aria-label="Eliminar"
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        count={properties.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              )}
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      <DeleteModal
        open={showModal}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
      <ToastContainer />
    </Box>
  );
};

export default ListProperties;
