import React from 'react';

const TermsOfService = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
            <h1>TÉRMINOS DE USO</h1>

            <h2>1. ACEPTACIÓN DE LOS TÉRMINOS</h2>
            <p>
                Al acceder y utilizar nuestra plataforma, usted acepta cumplir con los siguientes Términos de Uso.
                Estos términos rigen el acceso y uso de nuestros servicios, por lo que es importante que los lea detenidamente.
                Si no está de acuerdo con alguno de estos términos, no debe utilizar nuestra plataforma.
            </p>

            <h2>2. REGISTRO Y ACCESO A LA CUENTA</h2>

            <h3>2.1. Registro de Usuario</h3>
            <p>
                Para utilizar nuestros servicios, los usuarios deben registrarse proporcionando su dirección de correo
                electrónico o utilizando la opción de inicio de sesión a través de Google. Al registrarse, usted se
                compromete a proporcionar información veraz, completa y actualizada. Es su responsabilidad mantener la
                confidencialidad de su cuenta y contraseña.
            </p>

            <h3>2.2. Uso de la Cuenta</h3>
            <p>
                Solo los usuarios registrados pueden acceder y utilizar las funciones completas de nuestra plataforma,
                incluidas la gestión de propiedades y anuncios. Usted es responsable de todas las actividades que ocurran
                bajo su cuenta.
            </p>

            <h2>3. REGISTRO Y GESTIÓN DE PROPIEDADES Y ANUNCIOS</h2>

            <h3>3.1. Registro de Propiedades</h3>
            <p>
                Los usuarios pueden registrar sus propiedades en nuestra plataforma, proporcionando toda la información
                requerida de manera precisa y veraz. Usted es el único responsable de garantizar que la información de su
                propiedad esté actualizada.
            </p>

            <h3>3.2. Registro de Anuncios de Airbnb</h3>
            <p>
                Además del registro de propiedades, nuestra plataforma permite registrar anuncios de Airbnb asociados a
                dichas propiedades. Al hacerlo, usted nos autoriza a gestionar y actualizar la información relacionada con
                estos anuncios.
            </p>

            <h3>3.3. Gestión de Propiedades y Anuncios</h3>
            <p>
                Nuestro sistema facilita la gestión de sus propiedades y anuncios de Airbnb. Sin embargo, no garantizamos
                ningún tipo de resultado específico, como ocupación o ingresos.
            </p>

            <h2>4. PROGRAMACIÓN DE REUNIONES</h2>
            <p>
                Los usuarios pueden programar reuniones a través de nuestra plataforma para discutir detalles relacionados
                con la gestión de sus propiedades. La programación de una reunión no garantiza ningún resultado específico
                y está sujeta a la disponibilidad de nuestros agentes.
            </p>

            <h2>5. PAGOS</h2>

            <h3>5.1. Métodos de Pago</h3>
            <p>
                Nuestra plataforma acepta pagos mediante tarjeta de crédito/débito y PSE (un método de pago colombiano).
                Al realizar un pago, usted garantiza que tiene la autorización necesaria para utilizar el método de pago
                seleccionado.
            </p>

            <h3>5.2. Políticas de Pago</h3>
            <p>
                Todas las tarifas aplicables se cobrarán de acuerdo con los precios vigentes en el momento del pago. Los
                pagos no son reembolsables, excepto en casos específicos previstos por la ley aplicable.
            </p>

            <h2>6. OBLIGACIONES DEL USUARIO</h2>
            <p>
                Al utilizar nuestra plataforma, usted se compromete a:
                <ul>
                    <li>No proporcionar información falsa o engañosa.</li>
                    <li>No infringir derechos de terceros, incluidos derechos de propiedad intelectual.</li>
                    <li>No utilizar nuestra plataforma para fines ilegales o no autorizados.</li>
                    <li>No dañar, interferir o alterar la funcionalidad de nuestra plataforma.</li>
                </ul>
            </p>

            <h2>7. LIMITACIÓN DE RESPONSABILIDAD</h2>
            <p>
                Nuestra plataforma se proporciona "tal cual" y "según disponibilidad". No garantizamos que la plataforma
                esté libre de errores o interrupciones. No seremos responsables por cualquier daño directo, indirecto,
                incidental o consecuente que surja del uso o la imposibilidad de usar nuestra plataforma.
            </p>

            <h2>8. MODIFICACIONES A LOS TÉRMINOS</h2>
            <p>
                Nos reservamos el derecho de modificar estos Términos de Uso en cualquier momento. Las modificaciones
                entrarán en vigor inmediatamente después de su publicación en nuestra plataforma. Es su responsabilidad
                revisar estos términos periódicamente.
            </p>

            <h2>9. LEY APLICABLE Y JURISDICCIÓN</h2>
            <p>
                Estos Términos de Uso se regirán e interpretarán de acuerdo con las leyes de [PAÍS], sin tener en cuenta
                sus principios de conflicto de leyes. Cualquier disputa derivada de estos términos se someterá a la
                jurisdicción exclusiva de los tribunales de Medellín/Colombia.
            </p>

            <h2>10. CONTACTO</h2>
            <p>
                Si tiene alguna pregunta o inquietud sobre estos Términos de Uso, puede contactarnos a través de
                sfspacov@gmail.com.
            </p>
        </div>
    );
};

export default TermsOfService;
