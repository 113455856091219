import React from 'react';
import { Grid, TextField, MenuItem } from '@mui/material';

const CityNeighborhoodSelector = ({
    ciudad,
    handleCiudadChange,
    barrio,
    setBarrio,
    cities,
    barriosFiltrados,
    disabled,
    nickname,
    setNickname,
}) => {
    return (
        <>
            <Grid item xs={4}>
                <TextField
                    select
                    label="Ciudad"
                    value={ciudad}
                    onChange={handleCiudadChange}
                    fullWidth
                    margin="normal"
                    disabled={disabled}
                >
                    {cities.map((city) => (
                        <MenuItem key={city.id} value={city}>
                            {city.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={4}>
                <TextField
                    select
                    label="Barrio"
                    value={barrio}
                    onChange={(e) => setBarrio(e.target.value)}
                    fullWidth
                    margin="normal"
                    disabled={!ciudad && disabled} // Disables the Barrio dropdown if Ciudad is not selected or if `disabled` is true
                >
                    {barriosFiltrados.map((neighborhood) => (
                        <MenuItem key={neighborhood.id} value={neighborhood.name}>
                            {neighborhood.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Apodo"                    
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                    fullWidth
                    margin="normal"
                    disabled={!ciudad && disabled} 
                    inputProps={{ maxLength: 20 }}
                >
                </TextField>
            </Grid>
        </>
    );
};

export default CityNeighborhoodSelector;
