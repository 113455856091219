// src/pages/ManageAdsPage.js

import React from 'react';
import { Box, Typography } from '@mui/material';

const ManageAdsPage = () => {
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Manejar Anuncio
      </Typography>
      <Typography variant="body1">
        Esta página permite que você gerencie anúncios.
      </Typography>
      {/* Adicione o conteúdo e funcionalidades específicos para "Manejar Anuncio" aqui */}
    </Box>
  );
};

export default ManageAdsPage;
