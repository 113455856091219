import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";

const ScheduleConfirmation = () => {

  const query = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  useEffect(() => {
    const propertyId = sessionStorage.getItem("propertyId");
    if (propertyId === null) {
      navigate("/reschedule-confirmation");
    } else {
      sessionStorage.removeItem('propertyId');
      const token = localStorage.getItem('token');
      const start_time = query.get("startTime");
      const decodedDateTime = decodeURIComponent(start_time);
      const dateObject = new Date(decodedDateTime);
      const date = dateObject.toISOString().split('T')[0]; // YYYY-MM-DD
      const time = dateObject.toTimeString().split(' ')[0]; // HH:MM:SS

      fetch(`${process.env.REACT_APP_API_URL}/Schedule/Create/${propertyId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          date,
          time,
        }),
        credentials: 'include'
      })
        .then(response => {
          if (!response.ok) {
            return response.json().then(errorData => {
              throw new Error(errorData.message || 'Unknown error occurred');
            });
          }
          if (response.status === 201 || response.status === 204) {
            return;
          }
          return response.json();
        })
        .then(() => {
          if (propertyId) {
            sessionStorage.setItem("showScheduleToast", true);
            navigate(`/property/${propertyId}`);
          }
        })
        .catch(error => {
          sessionStorage.setItem("cantSaveMeeting", error.message);
          navigate(`/my-properties`);
        });
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array to run once on component mount

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
      <CircularProgress size={80} />
    </div>
  );
};

export default ScheduleConfirmation;
