// BookingsList.js
import React, { useEffect, useState } from 'react';
import { getProperties } from '../utils/propertyUtils';
import {
    Card, CardContent, Typography, CircularProgress, Chip, Box,
    Tabs, Tab, IconButton
} from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const ListMeetings = () => {
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const data = await getProperties();
            setProperties(data.filter(x => x.meeting).sort((a, b) => new Date(a.meeting) - new Date(b.meeting)));
            setLoading(false);
        };
        fetchData();
    }, []);

    const isPastMeeting = (date) => new Date(date) < new Date();

    // Separate properties into future and past meetings
    const futureMeetings = properties.filter(property => !isPastMeeting(property.meeting));
    const pastMeetings = properties.filter(property => isPastMeeting(property.meeting));

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleDetailClick = (property) => {
        navigate(`/property/${property.id}`);
    };

    const openMeetingRoom = (url) => {
        window.open(url, '_blank');
    };

    const renderMeetings = (meetings) => (
        meetings.map((property, index) => (
            <Card
                key={index}
                sx={{
                    mb: 2,
                    p: 2,
                    backgroundColor: isPastMeeting(property.meeting) ? '#f7f7f7' : '#e3f2fd',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <CardContent sx={{ flex: 1 }}>
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{
                            textDecoration: isPastMeeting(property.meeting) ? 'line-through' : 'none',
                            color: isPastMeeting(property.meeting) ? 'text.secondary' : 'text.primary',
                        }}
                    >
                        <h4>{format(new Date(property.meeting), "yyyy/MM/dd - hh:mm:ss")}</h4>
                        <b>Propiedad:</b><br /> {property.nickname} ubicada en {property.neighborhood}, {property.city}
                        <br />
                    </Typography>
                </CardContent>
                
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ml: 1 }}>
                    <Chip
                        label="Detalles de la Propriedad"
                        color="primary"
                        variant="outlined"
                        onClick={() => handleDetailClick(property)}
                        sx={{ mb: 1 }}
                    />
                    <IconButton
                        color="primary"
                        onClick={() => openMeetingRoom(process.env.REACT_APP_MEETING_LINK)}
                        aria-label="Open meeting room"
                        disabled={isPastMeeting(property.meeting)}
                        sx={{ color: isPastMeeting(property.meeting) ? 'grey.400' : 'primary.main' }}
                    >
                        <OpenInNew />
                    </IconButton>
                    <Typography variant="caption" color="textSecondary">
                        Accese la sala de reunion
                    </Typography>
                </Box>
            </Card>
        ))
    );

    return (
        <Box sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Tabs value={selectedTab} onChange={handleTabChange} centered>
                        <Tab label="Próximas Reuniones" />
                        <Tab label="Reuniones Pasadas" />
                    </Tabs>

                    <Box mt={2}>
                        {selectedTab === 0 && renderMeetings(futureMeetings)}
                        {selectedTab === 1 && renderMeetings(pastMeetings)}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ListMeetings;
