import React from 'react';
import Slider from 'react-slick';
import { Box, Container, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

const testimonialsData = [
  {
    text: 'El sistema mejoró significativamente la calidad de mis anuncios. ¡Las fotos son impresionantes y las descripciones perfectas!',
    author: 'Juan Pérez',
    rating: '⭐⭐⭐⭐⭐',
  },
  {
    text: '¡Un servicio excepcional! La atención al cliente es excelente y los resultados son rápidos.',
    author: 'Ana Gómez',
    rating: '⭐⭐⭐⭐⭐',
  },
  {
    text: 'Recomiendo este servicio a todos mis amigos. La calidad del trabajo es insuperable.',
    author: 'Carlos López',
    rating: '⭐⭐⭐⭐⭐',
  },
  {
    text: 'Gran experiencia. El equipo hizo un trabajo fantástico con mis anuncios.',
    author: 'Maria Fernández',
    rating: '⭐⭐⭐⭐⭐',
  },
];

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    prevArrow: (
      <ArrowBack
        sx={{
          color: '#333',
          fontSize: 40,
          '&:hover': { color: '#F5C250' }
        }}
      />
    ),
    nextArrow: (
      <ArrowForward
        sx={{
          color: '#333',
          fontSize: 40,
          '&:hover': { color: '#F5C250' }
        }}
      />
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Box id="testimonials" sx={{ py: 5, backgroundColor: '#f1f1f1' }}>
      <Container>
        <Typography variant="h4" align="center" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
          ¿Qué Dicen de Nosotros?
        </Typography>
        <Slider {...settings}>
          {testimonialsData.map((testimonial, index) => (
            <Paper
              key={index}
              sx={{
                p: 4,
                textAlign: 'center',
                transition: 'transform 0.3s ease',
                '&:hover': { transform: 'translateY(-10px)' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: '#ffffff',
                maxWidth: '100%',
                boxSizing: 'border-box',
              }}
            >
              <Typography variant="body1" sx={{ mb: 2, fontStyle: 'italic', color: '#555' }}>
                "{testimonial.text}"
              </Typography>
              <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
                - {testimonial.author}
              </Typography>
              <Box sx={{ color: '#f39c12', fontSize: '1.5rem' }}>
                {testimonial.rating}
              </Box>
            </Paper>
          ))}
        </Slider>
      </Container>
    </Box>
  );
};

export default Testimonials;
