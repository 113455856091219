// src/ProfilePage.js
import React from 'react';

const ProfilePage = () => {    
  const userInfo =JSON.parse(localStorage.getItem('user-info'));

  return (
    <div className="profile-container">
      <div className="profile-card">
        <img
          src={userInfo.picture}
          alt="Profile"
          className="profile-picture"
        />
        <h2>{userInfo.name}</h2>
        <p>{userInfo.email}</p>
      </div>

      {/* Inline CSS Styles */}
      <style jsx>{`
        .profile-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 20px;
        }

        .profile-card {
          border: 1px solid #ddd;
          border-radius: 8px;
          padding: 20px;
          text-align: center;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }

        .profile-picture {
          border-radius: 50%;
          width: 130px;
          height: 130px;
          object-fit: cover;
          margin-bottom: 20px;
        }
      `}</style>
    </div>
  );
};

export default ProfilePage;
