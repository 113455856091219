import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const PropertyData = ({ property }) => (
  <>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mb: 3,
        backgroundColor: '#1B447E',
        padding: 2,
        borderRadius: 1,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        align="center"
        sx={{ color: '#FFFFFF' }}
      >
        Detalles de {property.nickname ?? 'la Propiedad'}
      </Typography>
    </Box>
    <Box sx={{ mb: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Ciudad:</strong> {property.city}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Barrio:</strong> {property.neighborhood}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Huespedes:</strong> {property.guests}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Cuartos:</strong> {property.bedrooms}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Camas:</strong> {property.beds}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Baños:</strong> {property.baths}
          </Typography>
        </Grid>
        {property.estimatedValue > 0 && (
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
                  marginTop: '10px', // Moves the ScheduleSection up by 10px
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#FFF9C4', // Light yellow background for a "money" feel
              padding: 2,
              borderRadius: 1,
              boxShadow: 2,
              border: '1px solid #FFD700', // Gold border to emphasize money
              textAlign: 'center', // Center the text inside
            }}
          >
            <MonetizationOnIcon sx={{ color: '#FFD700', mr: 1 }} />
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#388E3C' }}>
              <strong>Ganancia diaria estimada:</strong> ${property.estimatedValue.toLocaleString('es-CO')}
            </Typography>
          </Box>
        </Grid>)
        }
      </Grid>
    </Box>
  </>
);

export default PropertyData;
