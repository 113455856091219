import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';

// Define styles for the hero section and overlay
const heroStyles = {
  backgroundImage: 'url("/home/house.png")',
  backgroundSize: 'cover', // Cover the entire area while maintaining aspect ratio
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center', // Always show the top of the image
  color: '#fff',
  py: 7, // Responsive padding
  textAlign: 'left',
  position: 'relative',
  overflow: 'hidden', // Ensure the overlay doesn't affect layout
};

const overlayStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 0,
};

const containerStyles = {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  left: 25,
  alignItems: 'left',
  justifyContent: 'left',
  height: '100%', // Ensure the container takes up the full height of the hero section
};

const titleStyles = {
  fontWeight: 'bold',
  fontSize: { xs: '2.5rem', md: '4rem' }, // Responsive font size
  mb: 2,
};

const subtitleStyles = {
  fontSize: { xs: '1.2rem', md: '1.5rem' }, // Responsive font size
  mb: 4,
  maxWidth: '600px', // Limit width for readability
  whiteSpace: 'nowrap', // Prevent text wrapping
};

const HeroSection = () => (
  <Box sx={heroStyles}>
    <Box sx={overlayStyles} />
    <Container sx={containerStyles}>
      <Typography variant="h1" sx={titleStyles}>
        ¡Bienvenidos a Piso7!
      </Typography>
      <Typography variant="h5" sx={subtitleStyles}>
        Aumenta tus ganancias y Disminuye tus preocupaciones!<br /><br />
        Nuestro sistema gestionará tus propiedades en Airbnb
      </Typography>
      <Button sx={{maxWidth:130, backgroundColor:'#F5C250' }} variant="contained" color="primary" href="#contact">
        Contáctanos
      </Button>
    </Container>
  </Box>
);

export default HeroSection;
