import React, { useState, useEffect, useRef } from 'react';
import DeleteModal from '../../components/DeleteModal';
import { PropertyData, MapSection } from '../../components/Property/Details'
import {
  Container,
  Tooltip,
  Box,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams, useNavigate } from 'react-router-dom';
import { deleteProperty, getPropertyDetail } from '../../utils/propertyUtils';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScheduleSection from '../../components/Schedule/ScheduleSection';

const PropertyDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [mapHeight, setMapHeight] = useState(0);
  const [schedule, setSchedule] = useState();
  const infoBoxRef = useRef(null);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        setLoading(true);
        const propertyData = await getPropertyDetail(id);
        setProperty(propertyData);
        if (propertyData.meeting) {
          const decodedDateTime = decodeURIComponent(propertyData.meeting);
          const dateObject = new Date(decodedDateTime);
          const date = dateObject.toISOString().split('T')[0];
          const time = dateObject.toTimeString().split(' ')[0];

          setSchedule({ date, time })
          if (sessionStorage.getItem("showScheduleToast") === "true") {
            toast.success('Reunión agendada con exito!')
            sessionStorage.removeItem("showScheduleToast");
          }
        }
      } catch (error) {
        setError('Error fetching property data');
        toast.error('Propiedad no encontrada.');
        navigate('/my-properties');
      } finally {
        setLoading(false);
      }
    };
    fetchProperty();
  }, [id, navigate]);

  useEffect(() => {
    if (infoBoxRef.current) {
      setMapHeight(infoBoxRef.current.clientHeight * 1.48);
    }
  }, [property]);

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
        <CircularProgress size={80} />
      </div>
    )
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!property) {
    navigate('/my-properties');
    return null;
  }
  const state = "Antioquia";
  const country = "Colombia"
  const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(property.city)}%2C%20${encodeURIComponent(property.neighborhood)}%2C%20${state}%2C%20${country}&z=15&output=embed`;

  const handleDeleteClick = () => {
    setDeleteId(id);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setDeleteLoading(true);

    const onSuccess = () => {
      navigate('/my-properties');
    };
  
    const onFailure = () => {
      toast.error('Error al eliminar Propiedad.');
    };
    const onFinally =() =>{
      setDeleteLoading(false);
      setShowModal(false);
    }
  
    deleteProperty(deleteId, onSuccess, onFailure, onFinally);

  };

  const handleCancelDelete = () => {
    setDeleteId(null);
    setShowModal(false);
  };

  return (
    <>
      <Container sx={{ padding: 2 }}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: 3,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                position: 'relative',
              }}
            >
              <Box sx={{ position: 'absolute', top: 23, right: 23, zIndex: 1 }}>
                <Box
                  sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '100%',
                    padding: 1,
                    boxShadow: 1,
                  }}
                >
                  <Tooltip title="Eliminar">
                    <IconButton
                      color="error"
                      onClick={handleDeleteClick}
                      disabled={deleteLoading}
                      aria-label="Eliminar"
                    >
                      {deleteLoading ? <CircularProgress size={24} /> : <DeleteIcon />}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <CardContent ref={infoBoxRef} sx={{ flex: 1 }}>
                <PropertyData property={property} />
              </CardContent>

              <Box
                sx={{
                  marginTop: 'auto', // Pushes this Box to the bottom
                  padding: 2,
                  marginBottom: schedule? '20px':'50px' , // Moves the ScheduleSection up by 10px
                }}
              >
                <ScheduleSection schedule={schedule} propertyId={id} />
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <MapSection googleMapsUrl={googleMapsUrl} mapHeight={mapHeight} />
          </Grid>
        </Grid>

        <DeleteModal
          open={showModal}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />

        <ToastContainer />
      </Container>
    </>
  );
};

export default PropertyDetails;
