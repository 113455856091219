import React from 'react';
import { Features, HowItWorks, Testimonials, Contact, HeroSection } from '../components/Home';

const App = () => {

  return (
    <div>
      {console.log("env: " + process.env.REACT_APP_ENV)}
      <HeroSection></HeroSection>

      <Features></Features>

      <HowItWorks></HowItWorks>

      <Testimonials></Testimonials>

      <Contact></Contact>

    </div>
  );
};

export default App;
