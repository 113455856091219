import React from 'react';
import {
  Grid,
  TextField,
} from '@mui/material';
import CityNeighborhoodSelector from './CityNeighborhoodSelector';


const PropertyRegistrationForm = ({
  guests,
  setGuests,
  cuartos,
  setCuartos,
  banos,
  setBanos,
  beds,
  setBeds,
  ciudad,
  handleCiudadChange,
  barrio,
  setBarrio,
  cities,
  barriosFiltrados,
  disabled,
  nickname,
  setNickname
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <CityNeighborhoodSelector
          ciudad={ciudad}
          handleCiudadChange={handleCiudadChange}
          barrio={barrio}
          setBarrio={setBarrio}
          cities={cities}
          barriosFiltrados={barriosFiltrados}
          disabled={disabled}
          nickname={nickname}
          setNickname={setNickname}
        />
        <Grid item xs={3}>
          <TextField
            type="number"
            label="Huespedes"
            value={guests}
            onChange={(e) => setGuests(e.target.value)}
            fullWidth
            margin="normal"
            disabled={disabled}
            inputProps={{ min: 1, max: 20 }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            type="number"
            label="Cuartos"
            value={cuartos}
            onChange={(e) => setCuartos(e.target.value)}
            fullWidth
            margin="normal"
            disabled={disabled}
            inputProps={{ min: 1, max: 20 }}
          >
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            type="number"
            label="Camas"
            value={beds}
            onChange={(e) => setBeds(e.target.value)}
            fullWidth
            margin="normal"
            disabled={disabled}
            inputProps={{ min: 1, max: 20 }}
          >
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            type="number"
            label="Baños"
            value={banos}
            onChange={(e) => setBanos(e.target.value)}
            fullWidth
            margin="normal"
            disabled={disabled}
            inputProps={{ min: 1, max: 20 }}
          />
        </Grid>

      </Grid>
    </>
  );
};

export default PropertyRegistrationForm;
