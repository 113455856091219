import React from 'react';
import { Container, Grid, Typography, Box, Card, CardContent, Paper } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useLocation, useNavigate } from 'react-router-dom';

const PaymentSelectionPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { estimate } = location.state || {};

  if (!estimate) {
    return <Typography variant="h6" color="error">Dados da estimativa não encontrados.</Typography>;
  }

  const handlePaymentSelection = (paymentType) => {
    navigate(`/payment/${paymentType}`, { state: { estimate } });
  };

  return (
    <Container sx={{ padding: 3 }}>
      {/* Resumo da Compra */}
      <Typography variant="h4" gutterBottom align="center">Resumo da Compra: {estimate.tipo}</Typography>
      <Card sx={{ boxShadow: 3, mb: 3, padding: 3 }}>
        <CardContent>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1976d2', textAlign: 'center' }}>
            Valor: $ {estimate.presupuestoAnuncio.toLocaleString('es-CO') + " COP"}
          </Typography>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Cidade: {estimate.ciudad}</Typography>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Bairro: {estimate.barrio}</Typography>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Tipo:  {estimate.tipo}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>m²: {estimate.metros}</Typography>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Quartos: {estimate.cuartos}</Typography>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Banheiros: {estimate.banos}</Typography>
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4caf50', mt: 2 }}>
                Ganâncias: $ {(Number(estimate.gananciaAnuncio) * 0.7).toLocaleString('es-CO') + " COP"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Seleção de Método de Pagamento */}
      <Typography variant="h4" gutterBottom align="center">Selecione o Método de Pagamento</Typography>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3}>
            <Card sx={{ boxShadow: 3, cursor: 'pointer', height: '100%' }} onClick={() => handlePaymentSelection('credit-card')}>
              <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 3 }}>
                  <CreditCardIcon sx={{ fontSize: 80, color: '#1976d2' }} />
                  <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                    Cartão de Crédito
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3}>
            <Card sx={{ boxShadow: 3, cursor: 'pointer', height: '100%' }} onClick={() => handlePaymentSelection('pse')}>
              <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 3 }}>
                  <AccountBalanceIcon sx={{ fontSize: 80, color: '#4caf50' }} />
                  <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                    PSE
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PaymentSelectionPage;
