import React from 'react';
import { Box, TextField, Grid, Typography } from '@mui/material';

const AirbnbLinkInput = ({ airbnbLink, setAirbnbLink, handleAnalizeAirBnbUrl, loading, disabled }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>

        <TextField
          label="Link de Anúncio en Airbnb"
          value={airbnbLink}
          onChange={(e) => setAirbnbLink(e.target.value)}
          onBlur={handleAnalizeAirBnbUrl}
          fullWidth
          disabled={disabled}
          margin="normal"
        />
        {loading && (
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              mt: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="body2">Validando anuncio...</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default AirbnbLinkInput;
