import React from 'react';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import InfoIcon from '@mui/icons-material/Info';
import ScheduleEmbed from './ScheduleEmbed';

const ScheduleSection = ({ schedule, propertyId }) => {

  const onScheduleCallClick = () => {
    sessionStorage.setItem("propertyId", propertyId);
  };

  // Check if the meeting has passed
  const meetingDateTime = new Date(`${schedule?.date}T${schedule?.time}`);
  const isMeetingPast = schedule && meetingDateTime < new Date();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        {schedule ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 2,
              borderRadius: 2,
              border: '1px solid #d0d0d0',
              backgroundColor: '#f5f5f5',
              width: '100%',
              maxWidth: 400,
              position: 'relative',
            }}
          >
            {isMeetingPast ? (
              <Box sx={{ textAlign: 'center', padding: 2 }}>
                <Typography variant="body2" sx={{ fontSize: '0.875rem', color: '#555' }}>
                  Ya tuviste una reunión en acerca de esa propiedad en:<br></br>{schedule?.date} {schedule?.time}
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  gap: 1,
                }}
              >
                <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                  ¡Reunión Agendada!
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, marginTop: 1 }}>
                  <b>{schedule.date}</b>&nbsp;a las&nbsp;<b>{schedule.time}</b>&nbsp;&nbsp;&nbsp;
                  <a
                    href={process.env.REACT_APP_MEETING_LINK}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#007bff' }}
                  >
                    <Typography sx={{ fontSize: '0.875rem', marginRight: 0.5 }}>Accese la sala de reunion</Typography>
                    <LaunchIcon fontSize="small" />
                  </a>
                </Box>
                <Tooltip
                  title={
                    <span>
                      Para cancelar la reunión abra el correo del invite <br /> o su app de calendario (Google Calendar, Outlook,...)
                    </span>
                  }
                  arrow // optional, for an arrow pointing to the icon
                  placement="top"
                >
                  <IconButton sx={{ position: 'absolute', top: 3, right: 3 }}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}


          </Box>
        ) : (
          <ScheduleEmbed onScheduleCallClick={onScheduleCallClick}></ScheduleEmbed>
        )}
      </Box>
    </Box>
  );
};

export default ScheduleSection;
