import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, CircularProgress } from '@mui/material';

const CreditCardForm = () => {
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('COP');
  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const token = localStorage.getItem('token');

      const response = await fetch('/api/payments/credit-card', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: JSON.stringify({
          amount: parseFloat(amount),
          currency,
          creditCardNumber: cardNumber,
          creditCardExpirationDate: expirationDate,
          creditCardSecurityCode: securityCode,
          buyerEmail: email,
        }),
      });

      if (!response.ok) {
        throw new Error('Erro ao processar pagamento');
      }

      const data = await response.json();
      console.log(data);
      // Você pode redirecionar ou mostrar uma mensagem de sucesso aqui
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom align="center">
        Pagamento com Cartão de Crédito
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 600,
          margin: 'auto',
          gap: 2,
        }}
      >
        <TextField
          label="Amount"
          variant="outlined"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
        <TextField
          label="Currency"
          variant="outlined"
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
          required
        />
        <TextField
          label="Card Number"
          variant="outlined"
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
          required
        />
        <TextField
          label="Expiration Date (MM/YY)"
          variant="outlined"
          value={expirationDate}
          onChange={(e) => setExpirationDate(e.target.value)}
          required
        />
        <TextField
          label="Security Code"
          variant="outlined"
          type="password"
          value={securityCode}
          onChange={(e) => setSecurityCode(e.target.value)}
          required
        />
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        {error && (
          <Typography color="error" variant="body2" align="center">
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
          sx={{ mt: 2 }}
        >
          {loading ? (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh'
            }}>
              <CircularProgress size={80} />
            </div>
          ) : 'Pay with Credit Card'}
        </Button>
      </Box>
    </Container>
  );
};

export default CreditCardForm;
