// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import RegisterProperty from './pages/properties/Register';
import Login from './pages/Login';
import Home from './pages/Home';
import ListProperties from './pages/properties/List';
import PropertyDetails from './pages/properties/PropertyDetails';
import PrivateRoute from './components/PrivateRoute';
import ManageAdsPage from './pages/ManageAdsPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ManageAdsAndPropertiesPage from './pages/ManageAdsAndPropertiesPage';
import PaymentSelectionPage from './pages/payment/AvailableMethods';
import CreditCardForm from './pages/payment/CreditCard';
import Budget from './pages/Budget';
import ScrollToTop from './components/ScrollToTop';
import TermsOfService from './pages/TermsOfService';
import { Contact } from './components/Home';
import ProfilePage from './pages/Profile';
import { AuthProvider } from './context/AuthContext';
import AccessDenied from './pages/AccessDenied';
import Pse from './pages/payment/Pse';
import NotFound from './pages/NotFound';
import ScheduleConfirmation from './components/Schedule/ScheduleConfirmation';
import RescheduleConfirmation from './components/Schedule/RescheduleConfirmation';
import Admin from './pages/Admin';
import ListMeetings from './pages/ListMeetings';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/access-denied" element={<AccessDenied />} />
          <Route path="/budget" element={<Budget />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/manage-ads" element={<ManageAdsPage />} />
          <Route path="/manage-ads-and-properties" element={<ManageAdsAndPropertiesPage />} />
          <Route path="/my-meetings" element={<PrivateRoute><ListMeetings /> </PrivateRoute>} />
          <Route path="/my-properties" element={<PrivateRoute><ListProperties /></PrivateRoute>} />
          <Route path="/payment-selection" element={<PrivateRoute><PaymentSelectionPage /></PrivateRoute>} />
          <Route path="/payment/credit-card" element={<PrivateRoute><CreditCardForm /></PrivateRoute>} />
          <Route path="/payment/pse" element={<PrivateRoute><Pse /></PrivateRoute>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/profile" element={<PrivateRoute><ProfilePage /></PrivateRoute>} />
          <Route path="/property/:id" element={<PrivateRoute><PropertyDetails /></PrivateRoute>} />
          <Route path="/register-property" element={<RegisterProperty />} />
          <Route path="/reschedule-confirmation" element={<RescheduleConfirmation />} />
          <Route path="/schedule-confirmation" element={<ScheduleConfirmation />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="*" element={<NotFound />} />
          <Route path={process.env.REACT_APP_ADMIN_MENU} element={<PrivateRoute><Admin /></PrivateRoute>} />
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  );
};

export default App;
