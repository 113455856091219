import React from 'react';
import { Box, Container, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const featuresData = [
  {
    title: 'Home Staging',
    description: 'Diseño de interiores ajustando las comodidades internas para las necesidades del huésped.',
    icon: <HomeIcon sx={{ color: '#F5C250' }} fontSize="large" />, // Set color to #F5C250
  },
  {
    title: 'Plan de Acción',
    description: 'Evaluamos todo lo que necesita su inmueble para generarle más ingresos.',
    icon: <AssessmentIcon sx={{ color: '#F5C250' }} fontSize="large" />, // Set color to #F5C250
  },
  {
    title: 'Anuncios Optimizados',
    description: 'Utilizando estrategia de palabras-clave y optimización de anuncios, hacemos con que su inmueble acceda posiciones destacadas, para encontrar más huéspedes.',
    icon: <TrendingUpIcon sx={{ color: '#F5C250' }} fontSize="large" />, // Set color to #F5C250
  },
  {
    title: 'Gestión Integral',
    description: 'Gestión integral de su inmueble para alquiler en Airbnb y otras plataformas, incluyendo comunicación con los huéspedes antes y durante su estancia para ofrecer una hospitalidad excepcional.',
    icon: <ManageAccountsIcon sx={{ color: '#F5C250' }} fontSize="large" />, // Set color to #F5C250
  },
];

const Features = () => {
  const isSmallScreen = useMediaQuery('(max-width:958px)'); // Detects if the screen width is 958px or less

  return (
    <Box id="features" sx={{ py: 5, backgroundColor: '#f8f9fa' }}>
      <Container>
        <Typography variant="h4" align="center" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
          ¿Qué hacemos?
        </Typography>
        <Grid container rowSpacing={2} columnSpacing={2} justifyContent="center">
          {featuresData.map((feature, index) => (
            <Grid item key={index}>
              <Paper
                sx={{
                  width: isSmallScreen ? '400px' : '550px', // Conditional width based on screen size
                  height: '200px',
                  position: 'relative',
                  textAlign: 'center',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  pt: 2, // Padding top for inner content
                }}
              >
                {/* Icon in the top left corner, positioned absolutely */}
                <Box sx={{ position: 'absolute', top: 16, left: 16 }}>
                  {feature.icon}
                </Box>
                {/* Title centered horizontally and vertically aligned with icon */}
                <Typography
                  variant="h5"
                  sx={{
                    position: 'absolute',
                    top: 16, // Same top value as the icon to align vertically
                    left: '50%', // Center horizontally
                    transform: 'translateX(-50%)', // Adjust for centering
                  }}
                >
                  {feature.title}
                </Typography>
                {/* Description */}
                <Box sx={{ textAlign: 'center', mt: 6 }}>
                  <Typography variant="body1" sx={{ fontSize: '1.2rem' }}>
                    {feature.description}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Features;
