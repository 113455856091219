// src/styles/TableStyles.js

import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

// Linha da tabela estilizada
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

// Célula da tabela estilizada
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
}));

// Cabeçalho da tabela estilizado
export const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#1B447E', // Cor de fundo do cabeçalho
  color: 'white', // Cor do texto
  fontWeight: 'bold', // Texto em negrito
  padding: theme.spacing(2),
  textAlign: 'center'
}));
