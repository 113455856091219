// src/pages/ManageAdsAndPropertiesPage.js

import React from 'react';
import { Box, Typography } from '@mui/material';

const Budget = () => {
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Presupuesto
      </Typography>
      <Typography variant="body1">
      * El presupuesto incluye:
                  <br /> - Consultoría por llamada de video
                  <br /> - Sesión de fotos con fotógrafo profesional
                  <br /> - Creación/Mejora del Anuncio de AirBnb
                  <br /> - Gestión de la propiedad
      </Typography>
      {/* Adicione o conteúdo e funcionalidades específicos para "Presupuesto" aqui */}
    </Box>
  );
};

export default Budget;
