import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Typography, IconButton, useMediaQuery, Grid } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <>
      <br></br>
      <Box
        sx={{
          py: 4,
          backgroundColor: '#1C1C1E',
          color: '#F5F5F5',
          textAlign: 'center',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {/* Logo Section */}
            <Grid item xs={12} md={4}>
              <IconButton aria-label="logo" component={Link} to="/">
                <img src="/Logo-piso7-footer.png" alt="Logo" style={{ width: 150 }} />
              </IconButton>
              <Typography variant="body2" sx={{ color: '#B3B3B3', mt: 1 }}>
                © {new Date().getFullYear()} Piso7. Todos los derechos reservados.
              </Typography>
            </Grid>

            {/* Navigation Links */}
            <Grid item xs={12} md={4}>
              <Box>
                <Link
                  to="/privacy-policy"
                  style={{
                    color: '#F5F5F5',
                    textDecoration: 'none',
                    marginRight: 20,
                    marginLeft: 20,
                    fontSize: isMobile ? '0.8rem' : '1rem',
                    display: 'block',
                    marginBottom: isMobile ? 8 : 12,
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.color = '#F5C250')}
                  onMouseOut={(e) => (e.currentTarget.style.color = '#F5F5F5')}
                >
                  Politica de Privacidad
                </Link>
                <Link
                  to="/terms-of-service"
                  style={{
                    color: '#F5F5F5',
                    textDecoration: 'none',
                    marginRight: 20,
                    marginLeft: 20,
                    fontSize: isMobile ? '0.8rem' : '1rem',
                    display: 'block',
                    marginBottom: isMobile ? 8 : 12,
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.color = '#F5C250')}
                  onMouseOut={(e) => (e.currentTarget.style.color = '#F5F5F5')}
                >
                  Terminos de Uso
                </Link>
                <Link
                  to="/contact"
                  style={{
                    color: '#F5F5F5',
                    textDecoration: 'none',
                    marginRight: 20,
                    marginLeft: 20,
                    fontSize: isMobile ? '0.8rem' : '1rem',
                    display: 'block',
                    marginBottom: isMobile ? 8 : 12,
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.color = '#F5C250')}
                  onMouseOut={(e) => (e.currentTarget.style.color = '#F5F5F5')}
                >
                  Contátenos
                </Link>
              </Box>
            </Grid>

            {/* Social Media Links */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ color: '#F5C250', mb: 2 }}>
                Follow Us
              </Typography>
              <Box>
                <IconButton
                  aria-label="Facebook"
                  href="https://www.facebook.com/piso7.co/"
                  target="_blank"
                  sx={{
                    color: '#F5C250',
                    '&:hover': { color: '#3b5998' },
                  }}
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  aria-label="Instagram"
                  href="https://www.instagram.com/piso7.co/"
                  target="_blank"
                  sx={{
                    color: '#F5C250',
                    '&:hover': { color: '#E1306C' },
                  }}
                >
                  <InstagramIcon />
                </IconButton>
                <IconButton
                  aria-label="LinkedIn"
                  href="https://www.linkedin.com/company/piso7/about/"
                  target="_blank"
                  sx={{
                    color: '#F5C250',
                    '&:hover': { color: '#0077B5' },
                  }}
                >
                  <LinkedInIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
