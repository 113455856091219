import { toast } from 'react-toastify';

export const getProperties = () => {
    const token = localStorage.getItem('token');

    return fetch(`${process.env.REACT_APP_API_URL}/Properties/List`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.ok)
                return response.json()
        })
        .then(properties => {
            return properties;
        })
        .catch(error => {
            console.error('Error fetching properties:', error);
            throw error;
        });
};


export const getPropertyDetail = async (id) => {
    const token = localStorage.getItem('token');

    const propertyResponse = await fetch(`${process.env.REACT_APP_API_URL}/Properties/GetPropertyDetail/${id}`,
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    const property = await propertyResponse.json();
    return property;
};

export const deleteProperty = (deleteId, onSuccess, onFailure, onFinally) => {
    const token = localStorage.getItem('token');
    const url = `${process.env.REACT_APP_API_URL}/Properties/Delete/${deleteId}`;
  
    fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Authorization': token ? `Bearer ${token}` : '',
        'Content-Type': 'application/json',
      },
    })
      .then((propertyResponse) => {
        if (!propertyResponse.ok) {
          return propertyResponse.json().then((errorData) => {
            const errorMessage = errorData.message || 'Error al eliminar la propiedad.';
            toast.error(errorMessage);
            throw new Error(errorMessage);
          });
        }
        return onSuccess();
      })
      .then(() => {
        toast.success('Propiedad eliminada con éxito!');
      })
      .catch((error) => {
        if (onFailure)
          onFailure();
      })
      .finally(()=>{
        if (onFinally)
          onFinally()
      });
  };