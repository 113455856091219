import React, { useEffect, useContext } from 'react';
import { Container, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LoginWithGoogle from '../components/GoogleLogin';
import { AuthContext } from '../context/AuthContext';

function Login() {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [navigate, isAuthenticated]);

  return (
    <Container maxWidth="xs" sx={{ mt: 5 }}>
      <Typography variant="h4" sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold' }}>
        Iniciar Sesión
      </Typography>

      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <LoginWithGoogle sx={{ display: 'inline-block', width: '100%' }} />
      </Box>

    </Container>
  );
}

export default Login;
