import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';

const Admin = () => {
    const userInfo = JSON.parse(localStorage.getItem('user-info'));
    const isAdmin = userInfo && process.env.REACT_APP_ADMIN_USERS.split(',').includes(userInfo.email);

    useEffect(() => {
        if (!isAdmin) {
            window.location.replace("/");
        }
    }, [isAdmin]);

    const [selectedDate, setSelectedDate] = useState(null);    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [profitId, setProfitId] = useState('');
    const [estimatedValue, setEstimatedValue] = useState('');

    const downloadLogs = async () => {
        if (!selectedDate) {
            setError("Please select a date");
            return;
        }

        setLoading(true);
        setError(null);

        const year = selectedDate.getFullYear();
        const month = selectedDate.getMonth() + 1;
        const day = selectedDate.getDate();

        const url = `${process.env.REACT_APP_API_URL}/Logs?Year=${year}&Month=${month}&Day=${day}`;
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Unknown error occurred');
            }

            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = downloadUrl;

            const contentDisposition = response.headers.get('content-disposition');
            let fileName = `consolidateLog-${year}-${month}-${day}.txt`;

            if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                const match = contentDisposition.match(/filename="?(.+)"?/);
                if (match && match[1]) {
                    fileName = match[1];
                }
            }

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
        } catch (err) {
            toast.error(err.message);
        } finally {
            setLoading(false);
        }
    };

    // The function to call UpdateProfit API
    const updateProfit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        const url = `${process.env.REACT_APP_API_URL}/Properties/UpdateProfit/${profitId}`;
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(url, {
                method: 'PATCH',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ EstimatedValue: estimatedValue })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update profit');
            }

            toast.success('Ganancia actualizada exitosamente!');
            setEstimatedValue('');
            setProfitId('');
        } catch (err) {
            toast.error(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {isAdmin && <div style={{ display: 'flex', padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
                {/* Logs Download Section */}
                <div style={{ flex: 1, marginRight: '20px' }}>
                    <h1>Download Logs</h1>
                    <div style={{ marginBottom: '20px' }}>
                        <label htmlFor="datePicker">Selecione fecha: </label>
                        <DatePicker
                            id="datePicker"
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            dateFormat="yyyy-MM-dd"
                            minDate={new Date('2024-11-11')}
                            maxDate={new Date()}
                            isClearable
                            placeholderText="Choose a date after 2024-10-21"
                        />
                    </div>
                    <button onClick={downloadLogs} disabled={loading || !selectedDate} style={{ padding: '10px 20px', cursor: 'pointer' }}>
                        {loading ? 'Downloading...' : 'Download Logs'}
                    </button>
                    {error && <p style={{ color: 'red', marginTop: '20px' }}>{error}</p>}
                </div>

                {/* Profit Update Section */}
                <div style={{ flex: 1 }}>
                    <h1>Actualizar ganancia</h1>
                    <form onSubmit={updateProfit}>
                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="profitId">ID de la Propiedad (vea tu email):</label>
                            <br></br>
                            <input
                                type="number"
                                id="profitId"
                                value={profitId}
                                onChange={(e) => setProfitId(e.target.value)}
                                required
                                style={{ width: '20%', padding: '8px' }}
                            />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="estimatedValue">Ganancia diaria estimada:</label>
                            <br></br>
                            <input
                                type="number"
                                id="estimatedValue"
                                value={estimatedValue}
                                onChange={(e) => setEstimatedValue(e.target.value)}
                                required
                                style={{ width: '30%', padding: '8px' }}
                            />
                        </div>
                        <button type="submit" disabled={loading} style={{ padding: '10px 20px', cursor: 'pointer' }}>
                            {loading ? 'Actualizando...' : 'Actualizar ganancia'}
                        </button>
                    </form>
                    {error && <p style={{ color: 'red', marginTop: '20px' }}>{error}</p>}
                </div>
                <ToastContainer />
            </div>}
        </>
    );
};

export default Admin;
