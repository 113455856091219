import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

// Adding a global CSS rule
const globalStyles = `
  body {
    overflow-x: hidden;
  }
`;

const stepsData = [
  {
    imageSrc: '/home/paso1.jpeg',
    altText: 'Paso 1',
    title: 'Paso 1: Registrar Propiedad',
    description: 'Sube la información de tu propiedad y nosotros nos encargamos del resto',
  },
  {
    imageSrc: '/home/paso2.jpeg',
    altText: 'Paso 2',
    title: 'Paso 2: Optimización',
    description: 'Nuestra IA y expertos optimizarán tu anuncio para atraer más inquilinos',
  },
  {
    imageSrc: '/home/paso3.jpeg',
    altText: 'Paso 3',
    title: 'Paso 3: Publicación',
    description: 'Publicamos y gestionamos tus anuncios en las principales plataformas',
  },
];

const HowItWorks = () => (
  <>
    <style>{globalStyles}</style> {/* Apply global styles to remove horizontal scroll */}
    <Box id="how-it-works" sx={{ py: 5, backgroundColor: '#e9ecef' }}>
      <Container>
        <Typography variant="h4" align="center" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
          Cómo Funciona
        </Typography>
        <Grid container spacing={4}>
          {stepsData.map((step, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box sx={{ textAlign: 'center' }}>
                <img 
                  src={step.imageSrc} 
                  alt={step.altText} 
                  className="hover-zoom" 
                  style={{ maxWidth: '265px', marginBottom: '20px', transition: 'transform 0.3s ease-in-out' }} 
                />
                <Typography variant="h6" gutterBottom>
                  {step.title}
                </Typography>
                <Typography variant="body1">
                  {step.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  </>
);

export default HowItWorks;
