/* First make sure that you have installed the package */

/* If you are using yarn */
// yarn add @calcom/embed-react

/* If you are using npm */
// npm install @calcom/embed-react

import { getCalApi } from "@calcom/embed-react";
import { Button } from "@mui/material";
import { useEffect } from "react";
export default function ScheduleEmbed({ onScheduleCallClick }) {
    useEffect(() => {
        (async function () {
            const cal = await getCalApi({ "namespace": process.env.REACT_APP_CAL_data_cal_namespace});
            cal("ui", { "styles": { "branding": { "brandColor": "#000000" } }, "hideEventTypeDetails": false, "layout": "month_view" });
        })();
    }, [])
    return (
        <>
            <Button onClick={onScheduleCallClick}
                data-cal-namespace={process.env.REACT_APP_CAL_data_cal_namespace}
                data-cal-link={process.env.REACT_APP_CAL_data_cal_link}
                data-cal-config='{"layout":"month_view"}' 
                variant="contained" 
                color="secondary">
                Agendar Video Llamada
            </Button>
        </>)
};
