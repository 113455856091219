import React from 'react';
import { Box } from '@mui/material';

const MapSection = ({ googleMapsUrl, mapHeight }) => (
  <Box sx={{ height: mapHeight, boxShadow: 3, borderRadius: 2, overflow: 'hidden', display: 'flex' }}>
    <iframe
      width="100%"
      height="100%"
      src={googleMapsUrl}
      frameBorder="0"
      style={{ border: 0, borderRadius: '8px' }}
      allowFullScreen
      aria-label="Mapa de la propiedad"
      tabIndex="0"
      title="Mapa de Google Maps"
    ></iframe>
  </Box>
);

export default MapSection;
