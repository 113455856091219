import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1>POLÍTICA DE PRIVACIDAD</h1>
      <p><strong>Fecha de Vigencia: 18 de septiembre de 2024</strong></p>

      <p>
        Tu privacidad es importante para nosotros. Esta política de privacidad explica cómo
        recopilamos, usamos y protegemos tu información personal cuando utilizas nuestra aplicación.
      </p>

      <h2>1. INFORMACIÓN QUE RECOPILAMOS</h2>
      <p>Recopilamos la siguiente información cuando utilizas nuestra aplicación:</p>
      <ul>
        <li>
          <strong>Información de la cuenta de Google:</strong> Cuando inicias sesión con Google,
          podemos recopilar tu nombre, dirección de correo electrónico, foto de perfil y cualquier
          otra información que hayas hecho pública en tu cuenta de Google.
        </li>
      </ul>

      <h2>2. CÓMO USAMOS TU INFORMACIÓN</h2>
      <p>Utilizamos la información recopilada para los siguientes fines:</p>
      <ul>
        <li><strong>Autenticación:</strong> Para permitirte iniciar sesión en nuestra aplicación a través de Google.</li>
        <li><strong>Experiencia del Usuario:</strong> Para personalizar tu experiencia y garantizar el funcionamiento adecuado de la aplicación.</li>
        <li><strong>Seguridad y Mantenimiento:</strong> Para monitorear y mejorar la seguridad, funcionalidad y rendimiento de la aplicación.</li>
      </ul>

      <h2>3. COMPARTIR TU INFORMACIÓN</h2>
      <p>
        No vendemos, intercambiamos ni alquilamos tu información personal a terceros. Sin embargo,
        podemos compartir tu información con terceros para los siguientes fines:
      </p>
      <ul>
        <li>
          <strong>Proveedores de Servicios:</strong> Podemos compartir tu información con proveedores
          de servicios externos de confianza que nos asisten en la operación de la aplicación, como
          servicios de hosting o proveedores de análisis.
        </li>
        <li>
          <strong>Cumplimiento Legal:</strong> Podemos divulgar tu información si es requerido por la
          ley o en respuesta a solicitudes legales válidas.
        </li>
      </ul>

      <h2>4. SEGURIDAD</h2>
      <p>
        Nos tomamos en serio la protección de tu información personal y aplicamos medidas de seguridad
        adecuadas para salvaguardarla. Sin embargo, ningún método de transmisión por Internet es 100%
        seguro, por lo que no podemos garantizar la seguridad absoluta de tu información.
      </p>

      <h2>5. TUS OPCIONES</h2>
      <ul>
        <li>
          <strong>Información de la Cuenta:</strong> Puedes revisar y cambiar la información de tu
          cuenta accediendo a la configuración de tu cuenta de Google.
        </li>
        <li>
          <strong>Cookies y Seguimiento:</strong> Puedes controlar las cookies y las tecnologías de
          seguimiento a través de la configuración de tu navegador.
        </li>
      </ul>

      <h2>6. PRIVACIDAD DE LOS NIÑOS</h2>
      <p>
        Nuestra aplicación no está destinada a ser utilizada por niños menores de 13 años. No
        recopilamos deliberadamente información personal de niños menores de 13 años. Si nos damos
        cuenta de que hemos recopilado dicha información, tomaremos medidas para eliminarla.
      </p>

      <h2>7. CAMBIOS EN ESTA POLÍTICA</h2>
      <p>
        Podemos actualizar esta Política de Privacidad de vez en cuando. Cualquier cambio será
        publicado en esta página con una fecha de vigencia actualizada.
      </p>

      <h2>8. CONTÁCTANOS</h2>
      <p>Si tienes alguna pregunta o inquietud sobre esta Política de Privacidad, por favor contáctanos en:</p>
      <p>
        Piso7 <br />
        Medellín, Colombia <br />
        sfspacov@gmail.com
      </p>
    </div>
  );
};

export default PrivacyPolicy;
